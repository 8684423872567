import React, { useEffect, useState } from "react";
import Datatable from "../../components/Datatable";
import PageTitle from "../../components/PageTitle";
import { get } from "../../Services/ManageConstant.jsx";
import { deleteSomething } from "../../Services/TechnicianService";
import { columns } from "../../tabledata/ConstantData";

export default function ManageConstant() {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    getData(1);
  }, [loader]);

  const getData = async (page) => {
    setLoading(true);
    const response = await get();
    setData(response.data.data);
    setLoading(false);
    setLoader(true);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#00000014",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#00000014",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#00000014",
        },
      },
    },
  };
  return (
    <div>
      <PageTitle title={"services"} location={window.location.href} />
      <div className="main_heading">
        <div className="main_heading_flex">
          <h1 className="heading_text">Manage Constant</h1>
        </div>
      </div>
      <div className="header_main_section">
        <div className="dataTable_header">
          <h4 className="dataTable_header_text">Constant List</h4>
        </div>
        <Datatable
          columns={columns(data, deleteSomething, setLoader)}
          incomingData={data}
          customStyles={customStyles}
          loading={loading}
        />
      </div>
    </div>
  );
}
