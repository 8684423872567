import axios from "../constants/AxiosConfig";

export const get = async (body) => {
  try {
    const response = await axios.get(`/api/admin/Query/GetQueries?PageNumber=${body.pageNumber}&PageSize=${body.pageSize}&Search=${body.search}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const save = async (body) => {
  try {
    const response = await axios.post("/api/admin/Query/ReplyQueries", body);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getTech = async (body) => {
  try {
    const response = await axios.get(`/api/admin/Query/GetAllQueries?PageNumber=${body.pageNumber}&PageSize=${body.pageSize}&Search=${body.search}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const saveTech = async (body) => {
  try {
    const response = await axios.post("/api/admin/Query/ReplyTechnicianQueries", body);
    return response;
  } catch (error) {
    return error.response;
  }
};