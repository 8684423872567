import axios from "../constants/AxiosConfig";

export const get = async (body) => {
  try {
    // const response = await axios.get(
    //   `/api/Category/GetAll?PageNumber=${body?.pageNumber}&PageSize=${body?.pageSize}&Search=${body?.search}`
    // );
    const queryParams = [];

    if (body?.pageNumber) queryParams.push(`PageNumber=${body.pageNumber}`);
    if (body?.pageSize) queryParams.push(`PageSize=${body.pageSize}`);
    if (body?.search)
      queryParams.push(`Search=${encodeURIComponent(body.search)}`);

    // Join the query string segments with '&' and prepend with '?' if not empty
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    // Use the queryString in the GET request
    const response = await axios.get(`/api/Category/GetAll${queryString}`);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getById = async (id) => {
  try {
    const response = await axios.get(`/api/Category/GetByID?CategoryId=${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteSomething = async (body) => {
  try {
    const response = await axios.delete(
      "/api/Category/Delete?CategoryId=" + body
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const save = async (body) => {
  try {
    const response = await axios.post("/api/Category/Add", body);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const update = async (body) => {
  try {
    const response = await axios.put("/api/Category/Update", body);
    return response;
  } catch (error) {
    return error.response;
  }
};
