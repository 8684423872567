import { Link } from "react-router-dom";
import deleteItem from "../components/deleteItem";

export const columnNames = [
  {
    type: "",
    price: "",
  },
];

export const pdfHeaders = ["type", "price"];

export const columns = (data, service, setLoader) => {

  return [
    {
      name: "Sr#",
      cell: (row, index) => {
        if (index < 9) {
          return "0" + (index + 1);
        } else {
          return index + 1;
        }
      },
      sortable: true,
      width: "150px",
      maxWidth: "150px",
    },

    {
      name: "Name",
      cell: (row) => row["title"],
      sortable: true,
    },
    {
      name: "Value",
      cell: (row) => row["value"],
      sortable: true,
    },
    

    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div>
          <div className="table_actions">
            <Link to={`/constant/edit/${row.constantId}`}>
              <img src="/assets/images/Icon awesome-edit.svg" alt="" />
            </Link>
           
          </div>
        </div>
      ),
      width: "250px",
      maxWidth: "250px",
    },
  ];
};
