import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import ManageTechQueries from "../Pages/Queries/ManageTechQueries";
import ViewTechQuery from "../Pages/Queries/ViewTechQuery";

function TechQueriesRoutes({ children }) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <ManageTechQueries />
            </PrivateRoute>
          }
        />
        <Route
          path="view/:id/:status"
          element={
            <PrivateRoute>
              <ViewTechQuery />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default TechQueriesRoutes;
