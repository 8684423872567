import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import ManageConstant from "../Pages/Constant/ManageConstant";
// import AddService from "../Pages/Service/AddService";
import EditConstant from "../Pages/Constant/EditConstant";
// import ViewService from "../Pages/Service/ViewService";

function ServiceRoutes({ children }) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <ManageConstant />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/add"
          element={
            <PrivateRoute>
              <AddService />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/edit/:id"
          element={
            <PrivateRoute>
              <EditConstant />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/view/:id"
          element={
            <PrivateRoute>
              <ViewService />
            </PrivateRoute>
          }
        /> */}
      </Routes>
    </>
  );
}

export default ServiceRoutes;
