import React, { useEffect, useState } from "react";
import Datatable from "../../components/Datatable";
import DataTableHeader from "../../components/Datatableheader";
import PageTitle from "../../components/PageTitle";
import { getTech } from "../../Services/QueriesService";
import { columns } from "../../tabledata/QueriesTechData";
import { pdfHeaders, columnNames } from "../../tabledata/QueriesTechData";

export default function ManageTechQueries() {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [filteredPdfData, setFilteredPdfData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);


  const filterPdfData = (data) => {
    const filteredData = data.map((elt) => {
      return [elt.userFullName, elt.subject, elt.message, elt.status];
    });
    setFilteredPdfData(filteredData);
  };

  useEffect(() => {
    getData(1);
  }, [loader]);

  const getData = async (page) => {
    setLoading(true);
    const response = await getTech({
      pageSize: perPage,
      pageNumber: page,
      search: "",
    });
    debugger;
    setData(response.data.data.queries);
    filterPdfData(response.data.data.queries);

    setLoading(false);
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await getTech({ pageSize: newPerPage, pageNumber: page });
    setData(response.data.data.queries);
    filterPdfData(response.data.data.queries);
    setPerPage(newPerPage);
    setLoading(false);
  };
  const searchInput = async (search) => {
    debugger;

    const response = await getTech({
      pageNumber: 1,
      pageSize: 10,
      search: search,
    });
    setData(response.data.data.queries);
    setTotalRows(response.data.data.total);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#00000014",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#00000014",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#00000014",
        },
      },
    },
  };
  return (
    <div>
      <PageTitle title={"Queries"} location={window.location.href} />
      <div className="main_heading">
        <div className="main_heading_flex">
          <h1 className="heading_text">Queries</h1>
        </div>
      </div>
      <div className="header_main_section">
        <DataTableHeader
          columnNames={columnNames}
          pdfHeaders={pdfHeaders}
          incomingFilteredData={filteredPdfData}
          incomingData={data}
          inComingName={"Queries"}
          title={"Technician Queries Lists"}
          search={searchInput}

        />
        <Datatable
          columns={columns(data)}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          incomingData={data}
          customStyles={customStyles}
          loading={loading}
        />
      </div>
    </div>
  );
}
