import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../constants/Loader.jsx";
import { get, update } from "../../Services/ManageConstant.jsx";
// import { get, update } from "../../Constant/ManageConstant";

export default function EditService() {
  const { id } = useParams();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [btnLock, setBtnLock] = useState(false);


  useEffect(() => {
    (async () => {
      debugger;
      const response = await get();
      console.log(response.data.data?.[0])
      reset(response.data.data?.[0]);
    })();
  }, [Loader]); //eslint-disable-line
  const navigate = useNavigate();
  const submitForm = async (formData) => {
  

    setBtnLock(true);

    const body = {
      constantId: id,
    //   title: "",
      value: "",
    };

    // body.title = formData.title;
    body.value = parseFloat(formData.value);
    
    const response = await update(body);

    if (response.data.code === 1) {
      setBtnLock(false);
      Swal.fire({
        position: "center",
        icon: "success",
        title: response.data.message,
        showConfirmButton: true,
        timer: 5000,
      });
      setTimeout(() => {
        navigate("/constant");
      }, 0);
    }

    if (response.data.code === 0) {
      setBtnLock(false);
      Swal.fire({
        position: "center",
        icon: "error",
        title: response.data.data.message,
        showConfirmButton: true,
        timer: 5000,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="main_heading">
          <div className="main_heading_flex">
            <Link to="/constant" className="NavLink">
              <div className="arrow_back">
                <img src="/assets/images/backArrow.svg" alt="" />
                <h1 className="heading_text">Edit Constant</h1>
              </div>
            </Link>
          </div>
        </div>
        <div className="header_main_section">
          <div className="section_main_heading">
            <h4 className="rider_main_heading">Constant Details</h4>
          </div>
          <div className="border_contains">
            <div className="border_bottom_item"></div>
          </div>
          <div className="users_main_section">
            <div className="row" style={{ paddingBottom: "50px" }}>
              <div className="col-md-6">
                <div className="input_container input_padding_main">
                  <label className="input_label">CONSTANT NAME</label>
                  <input
                    type="text"
                    placeholder="Enter"
                    className="section_input main_input"
                    {...register("title")}
                    disabled
                  />
                  {errors.type?.type === "required" && (
                    <p className="error_validation">Type is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="input_container input_padding_main">
                  <label className="input_label ">CONSTANT VALUE</label>
                  <input
                    type="number"
                    placeholder="Enter"
                    step={0.001}
                    className="section_input main_input"
                    {...register("value", { required: true })}
                  />
                  {errors.price?.type === "required" && (
                    <p className="error_validation">Price is required</p>
                  )}
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="button_section">
          <Link to="/constant">
            <button className="cancel_button">Cancel</button>
          </Link>
          <button type="submit" className="save_button">
            {btnLock ? (
              <div class="btnloader1 button_loader">{Loader}</div>
            ) : (
              " Update"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
